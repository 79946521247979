<template lang="pug">
  .display-step
    .sub-container
      .logo.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Site Logo
          .description.nio-p.text-primary-dark Your site's logo image URL. Note that the logo height is scaled to 28 pixels. The width of the logo will change based to the aspect ratio of the source image. An image with a transparent background is recommended.
        .filter-value
          NioTextField(
            v-model="model.logo"
            label="Site Logo URL"
          )
          NioTextField(
            v-model="model.logoAlt"
            label="Site Logo Alt Text"
          )
          .preview.logo(v-if="model.logo")
            .nio-p.text-primary-dark Preview:
            img.logo(
              :src="model.logo" 
              :alt="model.logoAlt"
            )
      .logo.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Placeholder Image
          .description.nio-p.text-primary-dark Provide a default image to be used for Data Streams in your shop.
        .filter-value
          NioTextField(
            v-model="model.placeholderImage"
            label="Placeholder Image URL"
          )
          .preview(v-if="model.placeholderImage")
            .nio-p.text-primary-dark Preview:
            NioImageTile(
              :src="model.placeholderImage"
              size="normal"
            )
      .favicon.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Favicon Icon
          .description.nio-p.text-primary-dark The icon that will appear next to the page title in the browser's title bar. Note that this icon will be scaled to 16 x 16 pixels.
        .filter-value
          NioTextField(
            v-model="model.favicon"
            label="Favicon Icon URL"
          )
          .preview.favicon(v-if="model.favicon")
            .nio-p.text-primary-dark Preview:
            img.logo(
              :src="model.favicon" 
              :alt="model.favicon"
            )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      logo: null,
      logoAlt: null,
      placeholderImage: null,
      favicon: null
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      const m = this.siteManifest
      this.model = {
        logo: m.global.logo ? m.global.logo : null,
        logoAlt: m.global.logoAlt ? m.global.logoAlt : 'Logo',
        placeholderImage: m.global.placeholderImage ? m.global.placeholderImage : null,
        favicon: m.config && m.config.favicon ? m.config.favicon : null
      }
    },
    makeSitePreviewObj() {
      return {
        globalColors: {
          // colors
        },
        // etc
      }
    },
    launchSitePreview() {
      parent.postMessage({
        name: 'launchDataShopPreview',
        payload: this.makeSitePreviewObj()
      }, "*")
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.display-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
      .preview
        margin-top: -2px
        .nio-p
          margin-bottom: 5px
        &.logo
          img
            display: flex
            align-items: center
            height: 1.75rem
        &.favicon
          img
            display: flex
            align-items: center
            width: 1rem
            height: 1rem
      
</style>
