<template lang="pug">
  .shop-settings-page
    NioDialog(
      v-model="savedDialog"
    )
      SavedDialog(@close="savedDialog = false")
    NioDialog(
      v-model="showDomainsDialog"
    )
      DomainsDialog(
        :domains="domains"
        @close="domainsDialog = false"
        @manifestLoaded="manifestLoaded($event)"
        @success="manifestLoaded"
        @createNew="showDomainsDialog = false"
        @loadManifestSuccess="loadManifestSuccessUpdated($event)"
      )
    NioDialog(
      v-model="manifestLoadedDialog"
    )
      ManifestLoadedDialog(
        :success="loadManifestSuccess"
        @close="closeManifestLoadedDialog"
      )
    .header.app-header
      h1.nio-h1.text-primary-darker Shop Settings
      .preview-container
        NioButton(
          icon-name="utility-external-link"
          normal-secondary-append
          @click="launchPreview"
        ) Preview Site
        .nio-p-small.text-primary-dark Click <strong>Save Settings</strong> to apply current changes
    .app-loading(v-if="loading || saving")
      v-progress-circular.progress(
        size="80" 
        indeterminate 
        color="#1438F5"
      )
    .customize-shop(v-if="siteManifest && !loading && !saving")
      NioTabs(
        v-model="activeTab"
        :tabs="tabs"
        :class="{'domain-step-only-enabled': !siteManifest || !siteManifest.url}"
      )
        template(
          v-slot:domain
        )
          .tab-content
            DomainStep(
              @stepUpdated="stepUpdated('domain', $event)"
            )
        template(
          v-slot:visibility
          :disabled="!siteManifest"
        )
          .tab-content
            VisibilityStep(     
              @stepUpdated="stepUpdated('visibility', $event)"
            )
        template(
          v-slot:display
          :disabled="!siteManifest"
        )
          .tab-content
            DisplayStep(
              @stepUpdated="stepUpdated('display', $event)"
            )
        template(
          v-slot:support
          :disabled="!siteManifest"
        )
          .tab-content
            SupportStep(
              @stepUpdated="stepUpdated('support', $event)"
            )
      .save-container
        NioButton(
          normal-secondary
          :disabled="saveDisabled"
          @click="save"
        ) Save Settings
        .error-message
          span.nio-p-small.text-error(v-if="saveDisabled") Domain is required
</template>

<script>

import DomainStep from './steps/DomainStep'
import VisibilityStep from './steps/VisibilityStep'
import DisplayStep from './steps/DisplayStep'
import SupportStep from './steps/SupportStep'
import messagesModule from '@/modules/messages'
import SavedDialog from '@/components/SavedDialog'
import DomainsDialog from '@/shared/components/DomainsDialog'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { mapGetters, mapActions } from 'vuex'
import DefaultSiteManifest from '@/modules/defaultSiteManifest'
import ManifestLoadedDialog from '@/shared/components/ManifestLoadedDialog'

export default {
  components: { DomainsDialog, ManifestLoadedDialog, DomainStep, VisibilityStep, DisplayStep, SupportStep, SavedDialog },
  data: () => ({
    bypass: false,
    domains: null,
    showDomainsDialog: false,
    loadManifestSuccess: false,
    manifestLoadedDialog: false,
    loading: true,
    saving: false,
    savedDialog: false,
    tabs: [
      {
        name: 'domain',
        label: 'Domain',
        payload: null
      },
      {
        name: 'visibility',
        label: 'Visibility',
        payload: null
      },
      {
        name: 'display',
        label: 'Appearance',
        payload: null
      },
      {
        name: 'support',
        label: 'Support',
        payload: null
      }
    ],
    payloads: {
      domain: null,
      visibility: null,
      display: null
    },
    activeTab: 0
  }),	
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
    const bypass = localStorage.getItem('nio:shop-builder-bypass')
    if (bypass) {
      this.bypass = true
      localStorage.removeItem('nio:shop-builder-bypass')
    }
    
  },
  computed: {
    ...mapGetters(['siteManifest']),
    activeTabValid() {
      return true
    },
    saveDisabled() {
      return this.activeTab === 0 && (!this.tabs[0].payload || !this.tabs[0].payload.domain)
    }
  },
  methods: {
    ...mapActions(['setSiteManifest', 'setDomainProperties', 'setDisplayProperties', 'setVisibilityProperties', 'setSupportProperties']),
    openApiInit() {
      if (!this.bypass) {
        this.getCompanyDomains().then(domains => {
          this.domains = domains.map((domain, index) => {
            return {
              id: index,
              name: domain
            }
          })
          if (domains && domains.length > 0) {
            this.showDomainsDialog = true
          } else {
            this.setSiteManifest(DefaultSiteManifest)
          }
          this.loading = false
        })
      } else {
        this.setSiteManifest(DefaultSiteManifest)
        this.loading = false
      }
    },
    getCompanyDomains() {
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.get(`/company-info/${this.nioUser.companyId}/domains`).then(res => {
          resolve(res.data.domains)
        })
      })
    },
    manifestLoaded(manifest) {
      this.showDomainsDialog = false
    },
    stepUpdated(stepName, payload) {
      this.tabs.find(tab => tab.name === stepName).payload = payload
    },
    loadManifestSuccessUpdated(val) {
      this.loadManifestSuccess = val
      this.showDomainsDialog = false
      this.manifestLoadedDialog = true
    },
    closeManifestLoadedDialog() {
      this.manifestLoadedDialog = false
      if (!this.loadManifestSuccess) {
        this.showDomainsDialog = true
      }
    },
    save() {
      this.saving = true
      const step = this.tabs[this.activeTab]
      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      switch (step.name) {
        case 'domain':
          this.setDomainProperties(step.payload)
          break;
        case 'display':
          this.setDisplayProperties(step.payload)
          break;
        case 'visibility':
          this.setVisibilityProperties(step.payload)
          break;
        case 'support':
          this.setSupportProperties(step.payload)
          break;
        default:
          break;
      }
      messagesModule.addListener('dataShopManifestSaved', this.dataShopManifestSaved)
      parent.postMessage({
        name: 'saveDataShopManifest',
        payload: this.siteManifest
      },"*")
    },
    dataShopManifestSaved() {
      this.saving = false
      this.savedDialog = true
    },
    launchPreview() {
      parent.postMessage({
        name: 'launchDataShopPreview',
        payload: JSON.stringify(this.siteManifest)
      },"*")
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@narrative.io/tackle-box/src/styles/global/_colors"

.shop-settings-page
  padding: 24px
  padding-bottom: 38px
  .header
    margin-bottom: 24px
    display: flex
    justify-content: space-between
    .preview-container
      position: relative
      .nio-p-small
        position: absolute
        bottom: -22px
        right: 0px
        white-space: nowrap
  .customize-shop
    // padding: 24px
    // border: 1px solid $c-primary-lighter
    border-radius: 12px
    .tab-content
      overflow: hidden
    .nio-button
      margin: 24px auto 0px auto
  ::v-deep h2
    margin-top: 24px
    margin-bottom: 16px
  ::v-deep .v-slide-group__content.v-tabs-bar__content
    justify-content: flex-start !important
  
  .save-container
    position: relative
    width: 100%
    display: flex
    justify-content: center
    .error-message
      position: absolute
      bottom: -24px
      text-align: center
  .domain-step-only-enabled
    ::v-deep .v-slide-group__content.v-tabs-bar__content
      .v-tab:nth-child(3), .v-tab:nth-child(4)
        opacity: 0.5
        pointer-events: none
  
</style>