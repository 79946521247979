import { render, staticRenderFns } from "./DomainStep.vue?vue&type=template&id=aaf71d50&scoped=true&lang=pug&"
import script from "./DomainStep.vue?vue&type=script&lang=js&"
export * from "./DomainStep.vue?vue&type=script&lang=js&"
import style0 from "./DomainStep.vue?vue&type=style&index=0&id=aaf71d50&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaf71d50",
  null
  
)

export default component.exports