<template lang="pug">
  .visibility-step
    .sub-container
      .dsm.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Data Streams Marketplace
          .description.nio-p.text-primary-dark Make your shop visible to buyers on the Narrative Data Streams Marketplace.
        .filter-value
          NioSwitch(
            v-model="model.dsm"
            label="List my shop on the Narrative Data Streams Marketplace"
          )
      .login-registration.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Login and Registration
            NioPill(
              text="coming"
            )
          .description.nio-p.text-primary-dark Require visitors to log in or register before entering your Data Shop.
        .filter-value
          NioSwitch(
            v-model="model.authRequired"
            label="Require login and registration"
          )
      //- .password-protect.filter
      //-   .title-description
      //-     .filter-title.nio-h4.text-primary-darker Password Protect
      //-     .description.nio-p.text-primary-dark Cras justo odio, dapibus ac facilisis in, egestas eget quam.
      //-   .filter-value
      //-     NioSwitch(
      //-       v-model="model.passwordProtect"
      //-       label="Set password"
      //-     )
      //-     NioTextField(
      //-       v-if="model.passwordProtect"
      //-       v-model="model.password"
      //-       type="password"
      //-       label="Enter Password"
      //-     )
      //-     NioTextField(
      //-       v-if="model.passwordProtect"
      //-       v-model="model.verifyPassword"
      //-       type="password"
      //-       label="Confirm Password"
      //-     )
    //- .sub-container
    //-   .invite.filter
    //-     .title-description
    //-       .filter-title.nio-h4.text-primary-darker Invite by Email
    //-       .description.nio-p.text-primary-dark Cras justo odio, dapibus ac facilisis in, egestas eget quam.
    //-     .filter-value
    //-       .entry
    //-         NioTextField(
    //-           v-model="model.inviteEmail"
    //-           label="Email Address"
    //-         )
    //-         NioButton(
    //-           normal-secondary
    //-           @click="addInvite"
    //-           :disabled="!inviteEmailValid"
    //-         ) Invite
    //-       .invitations
    //-         .invitation(
    //-           v-for="invitation of model.invitations"
    //-         )
    //-           NioDivider(horizontal-solo)
    //-           .invitation-content
    //-             .nio-p.text-primary-darker {{ invitation }}
    //-             NioButton(
    //-               normal-icon
    //-               iconName="utility-trash"
    //-               @click="deleteInvite(invitation)"
    //-             )
    //-         NioDivider(
    //-           v-if="model.invitations.length > 0"
    //-           horizontal-solo
    //-         )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      dsm: null,
      authRequired: null, 
      // passwordProtect: false,
      // password: null,
      // verifyPassword: null,
      inviteEmail: null, 
      invitations: []
    }
  }),
  computed: {
    ...mapGetters(['siteManifest']),
    inviteEmailValid() {
      return this.model.inviteEmail && this.model.inviteEmail.length > 0
    }
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      this.model.dsm = this.siteManifest.config ? this.siteManifest.config.listOnDsm ? true : false : true
      this.model.authRequired = this.siteManifest.authRequired ? true : false
    },
    addInvite() {
      this.model.invitations.push(this.model.inviteEmail)
      this.model.inviteEmail = null
    },
    deleteInvite(invite) {
      this.model.invitations = this.model.invitations.filter(invitation => invitation !== invite)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.visibility-step
  .filter
    .title-description
      .nio-pill
        margin-left: 8px
        transform: translateY(-1.5px)
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
  .invite
    .entry
      width: 100%
      display: flex
      justify-content: space-between
      align-items: center
      .nio-text-field
        flex-grow: 2
        margin-right: 16px
        margin-bottom: 0px
  .invite
    width: 100%
    .nio-divider
      height: 1.8rem
    .invitations
      width: 100%
      .invitation
        width: 100%
        .invitation-content
          width: 100%
          display: flex
          justify-content: space-between
          align-items: center
          .nio-p
            flex-grow: 2
</style>
