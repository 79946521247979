<template lang="pug">
  .support-step
    .sub-container
      .dsm.filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Support Email Address
          .description.nio-p.text-primary-dark Provide a support email address where users can send questions and concerns.
        .filter-value
          NioTextField(
            v-model="model.supportEmail"
            label="Support Email Address"
          )
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  data: () => ({
    loading: false,
    model: {
      supportEmail: null
    }
  }),
  computed: {
    ...mapGetters(['siteManifest']),
    supportEmailValid() {
      return this.model.supportEmail && this.model.supportEmail.length > 0
    }
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('stepUpdated', val)
      }
    },
    siteManifest: {
      deep: true,
      handler() {
        this.updateModel()
      }
    }
  },
  mounted() {
    this.updateModel()
  },
  methods: {
    updateModel() {
      this.model.supportEmail = this.siteManifest.supportEmail ? this.siteManifest.supportEmail : null
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.support-step
  .filter
    .title-description
      .nio-pill
        margin-left: 8px
        transform: translateY(-1.5px)
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
  // .invite
  //   .entry
  //     width: 100%
  //     display: flex
  //     justify-content: space-between
  //     align-items: center
  //     .nio-text-field
  //       flex-grow: 2
  //       margin-right: 16px
  //       margin-bottom: 0px
  
</style>
