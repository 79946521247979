<template lang="pug">
  .domain-step
    
    .app-loading(v-if="!siteManifest")
      v-progress-circular.progress(size="80" indeterminate color="#1438F5")
    template(v-else)
      h2.nio-h2.text-primary-darker Custom Domain
      .sub-container
        .domain.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Domain
            .description.nio-p.text-primary-dark Your registered domain.
          .filter-value
            NioTextField(
              v-model="model.domain"
              :rules="[rules.required]"
              label="Domain"
              placeholder="yourdomainname.com"
              validate-on-blur
              @update="updateManifest"
            )
            //- NioButton.test-domain(
            //-   normal-secondary
            //-   @click="testDomain"
            //- ) Test Domain
            .instructions
              p.nio-p.text-primary-dark Make sure to point your domain to Narrative's DNS server. 
              //- p.nio-p.text-primary-dark Please go to your domain registrar website, open the DNS settings and take these steps:
              //- ul 
              //-   li.nio-p.text-primary-dark Confirm your domain ownership—add a TXT-record with @ as the Hostname and rm_verify=13dc87010c as the Value.
              //-   li.nio-p.text-primary-dark Add the A-record with @ as the Hostname and 54.194.41.141 as the Value.
              //- p.nio-p.text-primary-dark These changes can take anywhere between a couple of minutes and 72 hours to start working properly. When the updates come into effect, test again and hit the ‘Map’ button.
              a(href="https://kb.narrative.io/data-shop-domain-cloudflare" target="_blank") More info
      h2.nio-h2.text-primary-darker SEO and Analytics
      .sub-container
        .ga.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Google Analytics
            .description.nio-p.text-primary-dark Set a Google Analytics tag.
          .filter-value
            NioTextField(
              v-model="model.ga"
              label="Google Analytics"
              placeholder="GA-XXXXXX"
              @update="updateManifest"
            )
        .gtm.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Google Tag Manager
            .description.nio-p.text-primary-dark Set a Google Tag Manager tracking tag.
          .filter-value
            NioTextField(
              v-model="model.gtm"
              label="Google Tag Manager"
              placeholder="GTM-XXXXXX"
              @update="updateManifest"
            )
      .sub-container
        .shop-name.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Shop Name
            .description.nio-p.text-primary-dark This text appears on the web browser’s title bar.
          .filter-value
            NioTextField(
              v-model="model.shopName"
              label="Shop Name"
              @update="updateManifest"
            )
        .description.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Meta Description
            .description.nio-p.text-primary-dark This is the description of your Data Shop as it appears to a search engine.
          .filter-value
            NioTextarea(
              v-model="model.description"
              placeholder="Meta Description"
              @update="updateManifest"
              :rows="5"
            )
        .tags.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Meta Tags
            .description.nio-p.text-primary-dark These describe your website to search engines. Use the return key to save a tag.
          .filter-value
            NioTagsField(
              v-model="model.tags"
              :rows="5"
              label="Meta Tags"
              @update="updateManifest"
            )
        .featured-search-terms.filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Featured Search Terms
            .description.nio-p.text-primary-dark Suggested search terms that will appear as buttons below the search bar on the Home page. Use the return key to save a search term.
          .filter-value
            NioTagsField(
              v-model="model.featuredSearchTerms"
              :rows="5"
              label="Featured Search Terms"
              @update="updateManifest"
            )
</template>

<script>

import DomainsDialog from '@/shared/components/DomainsDialog'
import messagesModule from '@/modules/messages'
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    loading: true,
    model: {
      loadDomain: null,
      domain: null,
      ga: null,
      gtm: null,
      shopName: null,
      description: null,
      tags: [],
      featuredSearchTerms: []
    },
    rules: {
      required: function(value) { return !!value || 'Required' }
    }
  }),
  computed: {
    ...mapGetters(['siteManifest'])
  },
  watch: {
    siteManifest: {
      deep: true,
      handler(val) {
        this.updateModel(val)
      }
    }
  }, 
  mounted() {
    this.updateModel(this.siteManifest)
  },
  methods: {
    ...mapActions(['setSiteManifest', 'setDomainProperties']),
    openApiInit() {
      this.getCompanyDomains().then(domains => {
        this.domains = domains
        if (domains && domains.length > 0) {
          this.showDomainsDialog = true
        }
        this.loading = false
      })
    },
    getCompanyDomains() {
      return new Promise((resolve, reject) => {
        this.$nioOpenApi.get(`/company-info/${this.nioUser.companyId}/domains`).then(res => {
          resolve(res.data.domains)
        })
      })
    },
    updateModel() {
      this.model.domain = this.siteManifest.url,
      this.model.ga = this.siteManifest.config && this.siteManifest.config.ga ? this.siteManifest.config.ga : null,
      this.model.gtm = this.siteManifest.config && this.siteManifest.config.gtm ? this.siteManifest.config.gtm : null,
      this.model.shopName = this.siteManifest.config && this.siteManifest.config.shopName ? this.siteManifest.config.shopName : null,
      this.model.description = this.siteManifest.config && this.siteManifest.config.description ? this.siteManifest.config.description : null ,
      this.model.tags = this.siteManifest.config && this.siteManifest.config.tags ? this.siteManifest.config.tags : [],
      this.model.featuredSearchTerms = this.siteManifest.config && this.siteManifest.config.featuredSearchTerms ? this.siteManifest.config.featuredSearchTerms : []
    },
    updateManifest() {
      this.$emit('stepUpdated', this.model)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.domain-step
  .filter
    .title-description
      .description
        max-width: 320px
    .filter-value
      .nio-text-field, .nio-tags-field
        width: 100%
    &.domain
      .test-domain
        margin-top: 0px
      .instructions
        margin: 0px
        // p, ul, li, a
        //   margin-top: 24px
        a
          font-size: 14px
          line-height: 20px
          letter-spacing: 0.4px
          color: $c-primary-dark
          font-weight: 400
    &.load-domain
      .filter-value
        flex-direction: row
        .nio-text-field
          flex-grow: 2
        .nio-button
          margin-left: 16px
</style>
